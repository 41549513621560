<template>
  <v-menu
    v-model="dateRangeShown"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        append-icon="mdi-calendar"
        :single-line="singleLine"
        hide-details="auto"
        :rules="rules"
        :value="dateRangeShown ? dateRangeTemp : dateRangeText"
        readonly
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :label="label"
        :class="addClass"
        :outlined="outlined"
        :dense="dense"
        :prepend-inner-icon="prependInnerIcon"
      ></v-text-field>
    </template>

    <v-date-picker
      ref="picker"
      v-model="tempModel"
      no-title
      scrollable
      color="secondary"
      :show-current="false"
      :multiple="multiple"
      :type="type === 'year' ? 'month' : type"
      :max="max"
      :min="min"
      @click:date="() => (selectOnClick && type !== 'month' ? save() : {})"
      @click:month="() => (selectOnClick && type === 'month' ? save() : {})"
      @click:year="(e) => (selectOnClick && type === 'year' ? save(e) : {})"
      @dblclick:date="
        () => (selectOnDblClick && type !== 'month' ? save() : {})
      "
      @dblclick:month="
        () => (selectOnDblClick && type === 'month' ? save() : {})
      "
    >
      <v-btn
        v-if="showClearButton"
        text
        small
        color="#757575"
        class="ml-3"
        @click="clearDates"
      >
        <v-icon small class="mr-2">mdi-close-thick</v-icon>
        Clear
      </v-btn>
      <v-spacer />
      <template v-if="showSaveCancel || multiple">
        <v-btn text color="primary" @click="dateRangeShown = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="save"> OK </v-btn>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    disabled: {
      default: false
    },
    multiple: {
      default: false
    },
    value: {
      default: function () {
        return undefined;
      }
    },
    label: {
      default: undefined
    },
    addClass: {
      default: undefined
    },
    rules: {
      default: undefined
    },
    outlined: {
      default: false
    },
    dense: {
      default: false
    },
    singleLine: {
      default: false
    },
    prependInnerIcon: {
      default: undefined
    },
    hideDetails: {
      default: "auto"
    },
    type: {
      default: undefined
    },
    max: {
      default: undefined
    },
    min: {
      default: undefined
    },
    showClearButton: {
      default: false
    },
    showSaveCancel: {
      default: true
    },
    selectOnClick: {
      default: false
    },
    selectOnDblClick: {
      default: false
    }
  },
  data: () => ({
    dateRangeShown: false,
    tempModel: []
  }),
  watch: {
    dateRangeShown: {
      handler: function (val) {
        if (val) {
          if (this.type === "year") {
            this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
          }
          this.tempModel = this.localModel;
        }
      }
    }
  },
  computed: {
    localModel: {
      get() {
        return this.value;
      },
      set(localModel) {
        this.$emit("input", localModel);
        this.$emit("change", localModel);
      }
    },
    dateRangeText: function () {
      const moment = this.$moment;
      let format = "DD MMM YYYY";
      if (this.type === "month") {
        format = "MMM YYYY";
      } else if (this.type === "year") {
        format = "YYYY";
      }
      if (this.multiple) {
        return this.localModel.length
          ? this.localModel
              .map((el) =>
                moment(el).isValid() ? moment(el).format(format) : ""
              )
              .join(", ")
          : "";
      } else {
        return this.localModel && moment(this.localModel).isValid()
          ? moment(this.localModel).format(format)
          : "";
      }
    },
    dateRangeTemp: function () {
      const moment = this.$moment;
      let format = "DD MMM YYYY";
      if (this.type === "month") {
        format = "MMM YYYY";
      } else if (this.type === "year") {
        format = "YYYY";
      }
      if (this.multiple) {
        return this.tempModel.length
          ? this.tempModel
              .map((el) =>
                moment(el).isValid() ? moment(el).format(format) : ""
              )
              .join(", ")
          : "";
      } else {
        return this.tempModel && moment(this.tempModel).isValid()
          ? moment(this.tempModel).format(format)
          : "";
      }
    }
  },
  methods: {
    save: function (e) {
      if (e) {
        this.tempModel = "" + e;
        this.localModel = "" + e;
        this.dateRangeShown = false;
      } else {
        this.localModel = this.tempModel;
        this.dateRangeShown = false;
      }
    },
    clearDates: function () {
      if (this.multiple) {
        this.localModel = [];
      } else {
        this.localModel = undefined;
      }
      this.dateRangeShown = false;
    }
  }
};
</script>
<style>
.date-range-picker .days-count {
  color: #3a8a71;
}
</style>

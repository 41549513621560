<template>
  <v-dialog
    v-model="showDialog"
    scrollable
    :max-width="width"
    :persistent="true"
    :fullscreen="fullscreen"
    transition="dialog-bottom-transition"
  >
    <v-overlay :absolute="true" :value="isLoading" :z-index="100">
      <v-progress-circular
        :size="50"
        color="progress"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card v-if="!fullscreen" class="c-modal">
      <v-card-title
        class="d-flex justify-space-between align-center modal-header py-2 pr-2"
      >
        <span class="modal-title font-weight-bold">{{ title }}</span>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-7 pt-0 px-7" v-if="show">
        <slot />
      </v-card-text>
      <v-card-actions class="py-4">
        <slot name="action" />
      </v-card-actions>
    </v-card>
    <template v-else>
      <v-card class="c-modal">
        <v-card-title class="modal-header pa-0">
          <v-container>
            <v-btn icon @click="$emit('close')" class="mr-4">
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <span class="modal-title">{{ title }}</span>
            <div class="action">
              <slot name="action" />
            </div>
          </v-container>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-3" v-if="show">
          <slot name="above-content" />
          <!-- <v-container class="pa-7 elevation-1"> -->
          <slot />
          <!-- </v-container> -->
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  data: () => ({
    showDialog: false
  }),
  props: {
    width: {
      default: 600
    },
    title: {
      default: ""
    },
    show: {
      default: false
    },
    isLoading: {
      default: false
    },
    fullscreen: {
      default: false
    }
  },
  watch: {
    show: function (val) {
      this.showDialog = val;
    }
  }
};
</script>

<style>
.c-modal .v-card__actions {
  justify-content: center;
}

.v-dialog--fullscreen .c-modal {
  background-color: #ededed;
}
.v-dialog--fullscreen .c-modal .modal-header {
  background-color: #f9f9f9;
}
.v-dialog--fullscreen .c-modal .modal-header .modal-title {
  color: #414141;
}
.v-dialog--fullscreen .c-modal .v-card__text .container {
  border: 1px solid #ececec;
  background-color: #ffffff;
  border-radius: 8px;
}
.v-dialog--fullscreen .c-modal .action {
  float: right;
}
</style>
